<template>
  <div>
    <div class="header">
      <img src="@/assets/images/header.png" width="100%" />
    </div>
    <div class="navbar szse-navbar szse-navbar-pc">
      <!-- <div class="g-container clearfix">
        <div class="navbar-headers ">
          <a href="#" class="navbar-brand ennavbar-brand">
            <img
              class="nav-logo"
              src="@/assets/images/logo.png"
              alt="Shenzhen Stock Exchange"
            />
          </a>
        </div>
        <div class="pull-right langswitch-con">
          <a class="langeach cn btn-golangcn" href="#" >Search</a>
           <span class="sepline">|</span>
            <a class="langeach ft btn-golangft">繁体</a>
        </div>
        <div class="top-search-el">
          <form
            class="search-form sh-searchhint-container"
            action=""
            target="frameFile"
            style="position: relative;"
          >
            <input
              type="search"
              maxlength="100"
              class="search-input need-repaired-placeholder"
              autocomplete="off"
              placeholder="Search"
            />
            <input type="text" style="display: none;" />
            <span class="clear-keyword">×</span>
            <button class="search-btn" type="button" disabled>
              <span class="glyphicon glyphicon-search"></span>
            </button>
          </form>
          <div class="search-hot" style="display:none;"></div>
        </div>
      </div> -->
      <nav
        class="collapse navbar-collapse bs-navbar-collapse navbar-wrap"
        role="navigation"
      >
        <div class="g-container">
          <div class="nav-box bondnav-box">
            <ul class="nav navbar-nav">
              <li chnlcode="home" class="active">
                <a datapath="home" href="#">HOME</a>
              </li>
              <li chnlcode="listings">
                <a datapath="listings">LISTING</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="whySzse">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="whySzse">Why SZSE</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="standards">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="standards"
                              >Listing Standards</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="process">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="process">Listing Process</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="listFees">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="listFees">Listing Fees</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="faq">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="faq"
                              >Frequently Asked Questions</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="disclosures">
                <a datapath="disclosures">DISCLOSURE</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="announcements">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            DJIA :: Dow Jones Industrial Average The Dow Jones
                            Industrial Average, Dow Jones, or simply the Dow, is
                            a price-weighted measurement stock market index of
                            30 prominent companies listed on stock exchanges in
                            the United States.
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="profiles_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="profiles_link"
                              >Company Profiles</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="fund">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="fund">Fund Information</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="siteMarketData">
                <a datapath="siteMarketData">MARKET DATA</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="siteMarketDatas">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Market Data</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Stocks</a>

                            <a href="#"><i></i>Funds</a>

                            <a href="#"><i></i>Quotes Lookup</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="marketStatistics">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Market Statistics</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Market Overview</a>

                            <a href="#"><i></i>Securities Summary</a>

                            <a href="#"><i></i>Sector Summary</a>

                            <a href="#"><i></i>Top Counters</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="indices">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Indices</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Indices Performance</a>

                            <a href="#"><i></i>Indices Constituent</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="publication">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Publication</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Monthly Report</a>

                            <a href="#"><i></i>Fact Book</a>

                            <a href="#"><i></i>Research</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="products">
                <a datapath="products">PRODUCTS</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="equity">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Equity</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Overview</a>

                            <a href="#"><i></i>Main Board</a>

                            <a href="#"><i></i>ChiNext</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="lefuFunds">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="lefuFunds">Fund</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="bonds">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="bonds">Bonds</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="security">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="security"
                              >Asset-Backed Securities</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="options">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Options</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Overview</a>

                            <a href="#"><i></i>CSI 300 ETF Options</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="services">
                <a datapath="services">SERVICES</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="trading">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Trading</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Overview</a>

                            <a href="#"><i></i>Trading Calendar</a>

                            <a href="#"><i></i>Trading Fees</a>

                            <a href="#"><i></i>Margin Trading</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="membership">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="membership"
                              >Membership Services</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="stockConnect_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="stockConnect_link"
                              >Stock Connect</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="qr_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="qr_link">QFII &amp; RQFII</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="dataServices">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="dataServices"
                              >Data Services</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="siteRules">
                <a datapath="rules">RULES</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="siteRule">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="siteRule">SZSE Rules</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="enAbout">
                <a datapath="about">ABOUT</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="enAboutOverview">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enAboutOverview">Overview</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enAboutNews">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">News</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Speeches</a>

                            <a href="#"><i></i>SZSE News</a>

                            <a href="#"><i></i>New Listings</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enMilestones">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enMilestones">Milestones</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enOurBrand">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enOurBrand">Our Brand</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enOrganization">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enOrganization"
                              >Organization</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enMembers">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enMembers">Members</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enCorporate">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enCorporate"
                              >Corporate Social Responsibility</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enCrossborder_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enCrossborder_link"
                              >Cross-border Capital Services</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enContactus">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enContactus">Contact Us</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>

    <div class="g-locationnav">
      <div class="banner-wrap">
        <img
          src="http://res.static.szse.cn/enweb/common/images/commonbannerbg.jpg"
        />
      </div>
      <div class="g-container">
        <div class="g-banner-txt">
          <h2 class="hidden-xs">SZSE English</h2>
          <p>
            <img
              src="http://res.static.szse.cn/common/icon/er-banneradr.png"
            />LOCATION:
            <a href="./" class="CurrChnlCls">SZSE English</a>
          </p>
        </div>
      </div>
    </div>

    <div
      class="component"
      style="background-image:url('https://firebasestorage.googleapis.com/v0/b/lotto-landingpage.appspot.com/o/images%2F20200730_canva_newyork_stock-1024x576.png?alt=media&token=3af13deb-07a7-46fe-bda7-71ab7818804e')"
    >
      <div class="g-container">
        <div style="background-color: rgba(255, 255, 255, .5);padding: 2px;">
          <h1
            class="news-tit text-center"
            style="color:#000;text-shadow: 2px 0 #fff;letter-spacing:2px;font-weight:bold;"
          >
            <b>Dow Jones VISA Industrial Average</b>
          </h1>
        </div>
        <div class="list-wrap">
          <div class="index-wrap">
            <div class="index-detail">
              <div
                :class="{
                  title: true,
                  down: this.last.change < 0 ? false : true,
                  up: this.last.change >= 0 ? false : true,
                }"
                style="font-weight:800;"
              >
                {{ numberFormat(this.last.last_threeall, true) }} USD
              </div>
              <ul>
                <li style="border-bottom: 1px dashed #999;">
                  <span class="name">Prev.Close</span>
                  <span class="value">{{
                    this.last.threeall2
                      ? numberFormat(this.last.threeall2, true)
                      : this.last.threeall
                      ? numberFormat(this.last.threeall, true)
                      : numberFormat(this.last.open, true)
                  }}</span>
                </li>
                <li
                  style="border-bottom: 1px dashed #999;"
                  :class="{
                    'open-down': this.last.change < 0 ? false : true,
                    'open-up': this.last.change >= 0 ? false : true,
                  }"
                >
                  <span class="name">Open</span>
                  <span class="value change">
                    <span class="value-cont">
                      <i class="icon"></i>
                      {{ numberFormat(this.last.open, true) }}
                    </span>
                  </span>
                </li>
                <!-- <li :class="{'down':(this.last.change<0)?true:false,'up':(this.last.change>=0)?true:false}">
                                <span class="name">Last</span>
                                <span class="value change">
                                    <span class="value-cont">
                                        <i class="icon"></i>
                                        {{this.last.last_threeall}}
                                    </span>
                                </span>
                            </li> -->
                <li
                  style="border-bottom: 1px dashed #999;"
                  :class="{
                    down: this.last.change < 0 ? false : true,
                    up: this.last.change >= 0 ? false : true,
                  }"
                >
                  <span class="name">Change</span>
                  <span class="value change">
                    <span class="value-cont">
                      <i class="icon"></i>
                      {{ this.last.last_twoall }}
                    </span>
                  </span>
                </li>
                <li
                  style="border-bottom: 1px dashed #999;"
                  :class="{
                    down: this.last.change < 0 ? false : true,
                    up: this.last.change >= 0 ? false : true,
                  }"
                >
                  <span class="name">Change（%）</span>
                  <span class="value change">
                    <span class="value-cont">
                      <i class="icon"></i>
                      {{ this.last.change }}
                    </span>
                  </span>
                </li>
                <li style="border-bottom: 1px dashed #999;">
                  <span class="name">High</span>
                  <span class="value blue">
                    {{ numberFormat(this.last.max, true) }}
                  </span>
                </li>
                <li style="border-bottom: 1px dashed #999;">
                  <span class="name">Low</span>
                  <span class="value blue">
                    {{ numberFormat(this.last.min, true) }}
                  </span>
                </li>
                <li style="border-bottom: 1px dashed #999;">
                  <span class="name">Volume（10Bil.）</span>
                  <span class="value">{{ this.last.volumn10bil }}</span>
                </li>
                <li style="border-bottom: 1px dashed #999;">
                  <span class="name">Amount（100Mil.）</span>
                  <span class="value">{{ this.last.volumnmil }}</span>
                </li>
              </ul>
            </div>
            <div class="index-trend">
              <ul class="pic-tabs">
                <li class="active">
                  <a href="#">Real-time</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Daily</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Weekly</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Monthly</a>
                </li>
              </ul>
              <div class="tabs-content">
                <div
                  id="minutely"
                  class="tab-panel active"
                  _echarts_instance_="ec_1680574159124"
                  style="-webkit-tap-highlight-color: transparent; user-select: none; background: transparent;"
                >
                  <div
                    style="position: relative; overflow: hidden; width: 772px; height: 283px; padding: 0px; margin: 0px; border-width: 0px; cursor: default;"
                  >
                    <canvas
                      width="772"
                      height="283"
                      data-zr-dom-id="zr_0"
                      style="position: absolute; left: 0px; top: 0px; width: 772px; height: 283px; user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); padding: 0px; margin: 0px; border-width: 0px;"
                    ></canvas>
                  </div>
                  <div></div>
                </div>
              </div>
              <span class="update-time">{{
                showDate2(this.last.createdate)
              }}</span>
            </div>
          </div>
          <a class="loadingBtn"></a>
        </div>
      </div>
    </div>
    <div class="history">
      <h2
        style="text-align: center;padding-top: 20px;padding-bottom: 20px;font-weight: bold;"
      >
        Dow Jones VISA Component Index Historical Data
      </h2>
      <table
        class="tbl"
        data-test="historical-data-table"
        style="--freeze-column-width:100px;"
      >
        <thead class="datatable_head__ysELt">
          <tr class="datatable_row__qHMpQ">
            <th class="datatable_cell__0y0eu">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white;">Date</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Price</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Open</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">High</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Low</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Vol</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Change %</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="datatable_body__cs8vJ">
          <tr
            class="datatable_row__qHMpQ"
            data-test="historical-data-table-row"
            v-for="(item, index) in data"
          >
            <td
              class="datatable_cell__0y0eu font-bold"
              style="text-align: center;"
            >
              {{ showDate3(item.datetime) }}
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed"
              dir="ltr"
              :style="{ 'text-align': 'right', color: checkColor(item.twoall) }"
            >
              <template v-if="item.threeall">
                {{ numberFormat(item.threeall, true) }} ({{
                  numberFormat(item.twoall, true)
                }})
              </template>
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
              style="text-align: right;"
            >
              {{ numberFormat(item.open, true) }}
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
              style="text-align: right;"
            >
              {{ numberFormat(item.max, true) }}
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
              style="text-align: right;"
            >
              {{ numberFormat(item.min, true) }}
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
              data-test="volume-cell"
              style="text-align: right;"
            >
              {{ numberFormat(item.volumn, true) }}M
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed font-bold"
              dir="ltr"
              :style="{ 'text-align': 'right', color: checkColor(item.change) }"
            >
              {{
                item.change && item.change.toString().charAt(0) === "-"
                  ? item.change
                  : "+" + item.change
              }}%
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      style="padding-top: 20px;padding-bottom: 20px; justify-content: center; display: flex;"
    >
      <div
        class="mb-5 bg-background-surface border-b-2 pb-5 instrumentOverview_overview-section__n9K3u"
      >
        <div
          data-test="news-container"
          class="news-analysis_articles-container__SxAEy mobileAndTablet:px-3"
        >
          <div class="layout_row__DCQjA layout_row--space-between__IAcWa mb-3">
            <h2 class="text-2xl font-semibold mb-4 inline-block">
              <a
                class="inv-link inv-link-title large primary"
                data-test="link-news"
                href="#"
                ><span class="inv-link-title-text" style="font-size: 24px;"
                  >Dow Jones VISA Industrial Average News</span
                ><svg
                  viewBox="-26 -26 48 48"
                  width="1em"
                  fill="none"
                  style="height:auto"
                  class="inv-link-title-arrows inv-icon-RTL"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.9524 11.5L3.50794 2L2 3.69643L8.93651 11.5L2 19.3036L3.50794 21L11.9524 11.5ZM21 11.5L12.5556 2L11.0476 3.69643L17.9841 11.5L11.0476 19.3036L12.5556 21L21 11.5Z"
                    fill="currentColor"
                  ></path></svg
              ></a>
            </h2>
          </div>
          <ul class="" data-test="news-list">
            <li
              class="list_list__item__NpIVc news-analysis_articles-list-item__bJ_3h pb-3"
            >
              <article
                data-test="article-item"
                class="news-analysis_article__CdSRG grid gap-3.5"
              >
                <figure tabindex="-1" class="pt-0.5">
                  <a>
                    <div class="lazyload-wrapper ">
                      <img
                        src="https://i-invdn-com.investing.com/news/external-images-thumbnails/LYNXMPEJ3K0CC_L.jpg"
                        alt=""
                        data-test="item-image"
                        class="news-analysis_article-image__wgIhA"
                      />
                    </div>
                  </a>
                </figure>
                <div class="news-analysis_content__vBoNw text-xs">
                  <a
                    class="inv-link text-secondary font-bold text-sm whitespace-normal"
                    data-test="article-title-link"
                    href="#"
                    >Wall St posts slim gain ahead of big earnings week</a
                  >
                  <ul class="list_list--horizontal__35mej flex-wrap mt-0.5">
                    <li
                      class="list_list__item__NpIVc news-analysis_info-item__K_kgL"
                    >
                      <div
                        class="layout_row__DCQjA layout_row--start__41vcp items-center flex-wrap text-common-grey text-2xs mt-0.5"
                      >
                        <span class="mr-1 shrink-0">By</span
                        ><span data-test="news-provider-name" class="shrink-0"
                          >Reuters</span
                        ><span class="mx-1">-</span
                        ><time
                          data-test="article-publish-date"
                          datetime="2023-04-21T22:46:02+0000"
                          class="ml-1 shrink-0"
                          >4h ago</time
                        >
                      </div>
                    </li>
                    <li
                      class="list_list__item__NpIVc news-analysis_info-item__K_kgL"
                    >
                      <a
                        class="inv-link flex items-center text-2xs text-secondary"
                        href="#"
                        ><svg
                          viewBox="0 0 24 24"
                          width="1em"
                          fill="none"
                          style="height:auto"
                          class="mr-1"
                        >
                          <mask id="path-1-inside-1" fill="white">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.49057 2H20.5925C21.9208 2 23 3.1251 23 4.50984V16.2801C23 17.6649 21.9208 18.79 20.5925 18.79H19.3774V24C19.3774 24 13.7079 19.228 13 18.79H3.49057C2.16226 18.79 1 17.6649 1 16.2801V4.50984C1 3.1251 2.16226 2 3.49057 2Z"
                            ></path>
                          </mask>
                          <path
                            d="M19.3774 18.79V16.79H17.3774V18.79H19.3774ZM19.3774 24L18.0894 25.5301L21.3774 28.2976V24H19.3774ZM13 18.79L14.0524 17.0892L13.5687 16.79H13V18.79ZM3.49057 4H20.5925V0H3.49057V4ZM20.5925 4C20.7386 4 21 4.15042 21 4.50984H25C25 2.09978 23.1029 0 20.5925 0V4ZM21 4.50984V16.2801H25V4.50984H21ZM21 16.2801C21 16.6395 20.7386 16.79 20.5925 16.79V20.79C23.1029 20.79 25 18.6902 25 16.2801H21ZM20.5925 16.79H19.3774V20.79H20.5925V16.79ZM17.3774 18.79V24H21.3774V18.79H17.3774ZM13 16.79H3.49057V20.79H13V16.79ZM3.49057 16.79C3.39612 16.79 3.2698 16.748 3.15791 16.6366C3.0495 16.5288 3 16.4008 3 16.2801H-1C-1 18.7787 1.06696 20.79 3.49057 20.79V16.79ZM3 16.2801V4.50984H-1V16.2801H3ZM3 4.50984C3 4.38917 3.0495 4.2612 3.15791 4.15332C3.2698 4.04198 3.39612 4 3.49057 4V0C1.06696 0 -1 2.01129 -1 4.50984H3ZM19.3774 24C20.6653 22.4699 20.6652 22.4699 20.6652 22.4698C20.6651 22.4698 20.6651 22.4697 20.665 22.4696C20.6648 22.4695 20.6645 22.4692 20.6641 22.4689C20.6634 22.4683 20.6624 22.4674 20.661 22.4663C20.6582 22.4639 20.654 22.4604 20.6486 22.4558C20.6377 22.4467 20.6216 22.4331 20.6006 22.4155C20.5586 22.3802 20.497 22.3284 20.4185 22.2625C20.2614 22.1306 20.0364 21.9418 19.7643 21.714C19.2202 21.2585 18.4866 20.646 17.7302 20.0192C16.975 19.3934 16.192 18.7493 15.5507 18.2321C14.9724 17.7657 14.3634 17.2817 14.0524 17.0892L11.9476 20.4907C11.9317 20.4808 12.0146 20.5366 12.2532 20.7213C12.4607 20.8818 12.7285 21.0947 13.0395 21.3456C13.6603 21.8463 14.4275 22.4773 15.1781 23.0993C15.9275 23.7203 16.6556 24.3282 17.1966 24.7811C17.467 25.0075 17.6905 25.1949 17.8462 25.3257C17.9241 25.3911 17.985 25.4423 18.0264 25.4771C18.0471 25.4945 18.0629 25.5078 18.0736 25.5168C18.0789 25.5212 18.0829 25.5246 18.0855 25.5268C18.0869 25.5279 18.0879 25.5288 18.0885 25.5293C18.0888 25.5296 18.0891 25.5298 18.0892 25.5299C18.0893 25.53 18.0894 25.5301 18.0894 25.5301C18.0894 25.5301 18.0894 25.5301 19.3774 24Z"
                            fill="currentColor"
                            mask="url(#path-1-inside-1)"
                          ></path></svg
                        ><span>37</span></a
                      >
                    </li>
                  </ul>
                  <p
                    data-test="article-description"
                    class="news-analysis_description__A678y overflow-hidden mt-1.5"
                  >
                    By Lewis Krauskopf, Sruthi Shankar and Ankika Biswas
                    (Reuters) - Major U.S. stock indexes ended with fractional
                    gains on Friday following mixed earnings results as
                    investors...
                  </p>
                </div>
              </article>
            </li>
            <li
              class="list_list__item__NpIVc news-analysis_articles-list-item__bJ_3h pb-3"
            >
              <article
                data-test="article-item"
                class="news-analysis_article__CdSRG grid gap-3.5"
              >
                <figure tabindex="-1" class="pt-0.5">
                  <a>
                    <div class="lazyload-wrapper ">
                      <img
                        src="https://i-invdn-com.investing.com/news/LYNXMPEE7024A_S.jpg"
                        alt=""
                        data-test="item-image"
                        class="news-analysis_article-image__wgIhA"
                      />
                    </div>
                  </a>
                </figure>
                <div class="news-analysis_content__vBoNw text-xs">
                  <a
                    class="inv-link text-secondary font-bold text-sm whitespace-normal"
                    data-test="article-title-link"
                    href="#"
                    >Stock market today: Dow snaps 4-week losing streak amid
                    mixed quarterly earnings</a
                  >
                  <ul class="list_list--horizontal__35mej flex-wrap mt-0.5">
                    <li
                      class="list_list__item__NpIVc news-analysis_info-item__K_kgL"
                    >
                      <div
                        class="layout_row__DCQjA layout_row--start__41vcp items-center flex-wrap text-common-grey text-2xs mt-0.5"
                      >
                        <span class="mr-1 shrink-0">By</span
                        ><span data-test="news-provider-name" class="shrink-0"
                          >Investing.com</span
                        ><span class="mx-1">-</span
                        ><time
                          data-test="article-publish-date"
                          datetime="2023-04-21T20:56:17+0000"
                          class="ml-1 shrink-0"
                          >6h ago</time
                        >
                      </div>
                    </li>
                    <li
                      class="list_list__item__NpIVc news-analysis_info-item__K_kgL"
                    >
                      <a
                        class="inv-link flex items-center text-2xs text-secondary"
                        href="#"
                        ><svg
                          viewBox="0 0 24 24"
                          width="1em"
                          fill="none"
                          style="height:auto"
                          class="mr-1"
                        >
                          <mask id="path-1-inside-1" fill="white">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.49057 2H20.5925C21.9208 2 23 3.1251 23 4.50984V16.2801C23 17.6649 21.9208 18.79 20.5925 18.79H19.3774V24C19.3774 24 13.7079 19.228 13 18.79H3.49057C2.16226 18.79 1 17.6649 1 16.2801V4.50984C1 3.1251 2.16226 2 3.49057 2Z"
                            ></path>
                          </mask>
                          <path
                            d="M19.3774 18.79V16.79H17.3774V18.79H19.3774ZM19.3774 24L18.0894 25.5301L21.3774 28.2976V24H19.3774ZM13 18.79L14.0524 17.0892L13.5687 16.79H13V18.79ZM3.49057 4H20.5925V0H3.49057V4ZM20.5925 4C20.7386 4 21 4.15042 21 4.50984H25C25 2.09978 23.1029 0 20.5925 0V4ZM21 4.50984V16.2801H25V4.50984H21ZM21 16.2801C21 16.6395 20.7386 16.79 20.5925 16.79V20.79C23.1029 20.79 25 18.6902 25 16.2801H21ZM20.5925 16.79H19.3774V20.79H20.5925V16.79ZM17.3774 18.79V24H21.3774V18.79H17.3774ZM13 16.79H3.49057V20.79H13V16.79ZM3.49057 16.79C3.39612 16.79 3.2698 16.748 3.15791 16.6366C3.0495 16.5288 3 16.4008 3 16.2801H-1C-1 18.7787 1.06696 20.79 3.49057 20.79V16.79ZM3 16.2801V4.50984H-1V16.2801H3ZM3 4.50984C3 4.38917 3.0495 4.2612 3.15791 4.15332C3.2698 4.04198 3.39612 4 3.49057 4V0C1.06696 0 -1 2.01129 -1 4.50984H3ZM19.3774 24C20.6653 22.4699 20.6652 22.4699 20.6652 22.4698C20.6651 22.4698 20.6651 22.4697 20.665 22.4696C20.6648 22.4695 20.6645 22.4692 20.6641 22.4689C20.6634 22.4683 20.6624 22.4674 20.661 22.4663C20.6582 22.4639 20.654 22.4604 20.6486 22.4558C20.6377 22.4467 20.6216 22.4331 20.6006 22.4155C20.5586 22.3802 20.497 22.3284 20.4185 22.2625C20.2614 22.1306 20.0364 21.9418 19.7643 21.714C19.2202 21.2585 18.4866 20.646 17.7302 20.0192C16.975 19.3934 16.192 18.7493 15.5507 18.2321C14.9724 17.7657 14.3634 17.2817 14.0524 17.0892L11.9476 20.4907C11.9317 20.4808 12.0146 20.5366 12.2532 20.7213C12.4607 20.8818 12.7285 21.0947 13.0395 21.3456C13.6603 21.8463 14.4275 22.4773 15.1781 23.0993C15.9275 23.7203 16.6556 24.3282 17.1966 24.7811C17.467 25.0075 17.6905 25.1949 17.8462 25.3257C17.9241 25.3911 17.985 25.4423 18.0264 25.4771C18.0471 25.4945 18.0629 25.5078 18.0736 25.5168C18.0789 25.5212 18.0829 25.5246 18.0855 25.5268C18.0869 25.5279 18.0879 25.5288 18.0885 25.5293C18.0888 25.5296 18.0891 25.5298 18.0892 25.5299C18.0893 25.53 18.0894 25.5301 18.0894 25.5301C18.0894 25.5301 18.0894 25.5301 19.3774 24Z"
                            fill="currentColor"
                            mask="url(#path-1-inside-1)"
                          ></path></svg
                        ><span>6</span></a
                      >
                    </li>
                  </ul>
                  <p
                    data-test="article-description"
                    class="news-analysis_description__A678y overflow-hidden mt-1.5"
                  >
                    By Yasin Ebrahim Investing.com -- The Dow closed lower
                    Friday, snapping a four-week win streak on mixed quarterly
                    earnings ahead of results from big tech next week.&nbsp; The
                    Dow Jones...
                  </p>
                </div>
              </article>
            </li>
            <li
              class="list_list__item__NpIVc news-analysis_articles-list-item__bJ_3h pb-3"
            >
              <article
                data-test="article-item"
                class="news-analysis_article__CdSRG grid gap-3.5"
              >
                <figure tabindex="-1" class="pt-0.5">
                  <a>
                    <div class="lazyload-wrapper ">
                      <img
                        src="https://i-invdn-com.investing.com/news/external-images-thumbnails/LYNXMPEJ3K024_L.jpg"
                        alt=""
                        data-test="item-image"
                        class="news-analysis_article-image__wgIhA"
                      />
                    </div>
                  </a>
                </figure>
                <div class="news-analysis_content__vBoNw text-xs">
                  <a
                    class="inv-link text-secondary font-bold text-sm whitespace-normal"
                    data-test="article-title-link"
                    href="#"
                    >Stocks edge down, US yields climb after PMI data</a
                  >
                  <ul class="list_list--horizontal__35mej flex-wrap mt-0.5">
                    <li
                      class="list_list__item__NpIVc news-analysis_info-item__K_kgL"
                    >
                      <div
                        class="layout_row__DCQjA layout_row--start__41vcp items-center flex-wrap text-common-grey text-2xs mt-0.5"
                      >
                        <span class="mr-1 shrink-0">By</span
                        ><span data-test="news-provider-name" class="shrink-0"
                          >Reuters</span
                        ><span class="mx-1">-</span
                        ><time
                          data-test="article-publish-date"
                          datetime="2023-04-21T20:32:11+0000"
                          class="ml-1 shrink-0"
                          >7h ago</time
                        >
                      </div>
                    </li>
                  </ul>
                  <p
                    data-test="article-description"
                    class="news-analysis_description__A678y overflow-hidden mt-1.5"
                  >
                    By Chuck Mikolajczak NEW YORK (Reuters) - A gauge of global
                    stocks declined for a third straight day on Friday as
                    investors weighed the latest round of corporate earnings
                    results,...
                  </p>
                </div>
              </article>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="announce-wrap">
      <div class="g-container announce">
        <b>DJIA :: Dow Jones VISA Industrial Average</b><br />
        <font color="#FFF" size="3">
          The Dow Jones VISA Industrial Average, Dow Jones, or simply the Dow,
          is a price-weighted measurement stock market index of 30 prominent
          companies listed on stock exchanges in the United States.
        </font>
      </div>
    </div>
    <footer class="enfooter">
      <div class="enfooter-content">
        <div class="g-container">
          <div class="footer-navlinks hidden-xs" style="text-align: center;">
            <a href="#">LISTING</a>
            <a href="#">DISCLOSURE</a>
            <a href="#">MARKET DATA</a>
            <a href="#">PRODUCTS</a>
            <a href="#">SERVICES</a>
            <a href="#">RULES</a>
            <a href="#">ABOUT</a>
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        <div class="g-container hidden-xs">
          <div class="footersafe-con">
            <img
              id="imgConac"
              src="http://res.static.szse.cn/common/icon/footer-icon-1.png"
            />
            <img
              src="http://res.static.szse.cn/common/icon/footer-icon-2.png"
            />
            <img
              src="http://res.static.szse.cn/common/icon/footer-icon-3.png"
            />
          </div>
          <div class="con">
            <div class="rule-tip">
              <a class="lawspublishlink" href="#" target="_blank "
                >Disclaimer</a
              >
              &nbsp;
              <a class="copyright-tip" href="#">ICP05012689</a
              ><span
                >©<span class="copyright-year">2023</span> Dow Jones VISA
                Industrial Average. All Rights Reserved</span
              >
            </div>
            <div class="ie-tip ">
              IE9.0+，1280*800
            </div>
          </div>
        </div>

        <div class="footer-copyright-mobile visible-xs ">
          <div class="footersafe-con">
            <a
              href="//bszs.conac.cn/sitename?method=show&amp;id=541B4DBFF36A103AE053022819ACAC5A"
            >
              <img
                id="imgConac"
                src="http://res.static.szse.cn/common/icon/footer-icon-1.png"
              />
            </a>
            <a href="http://www.sznet110.gov.cn/netalarm/index.jsp">
              <img
                src="http://res.static.szse.cn/common/icon/footer-icon-2.png"
              />
            </a>
            <a
              href="http://www.sznet110.gov.cn/webrecord/innernet/Welcome.jsp?bano=4403201010119"
            >
              <img
                src="http://res.static.szse.cn/common/icon/footer-icon-3.png"
              />
            </a>
          </div>
          <div class="con">
            <a class="lawspublishlink" href="#" target="_blank ">Disclaimer</a>
            &nbsp;<a href="#">ICP05012689</a> <br />©<span
              class="copyright-year"
              >2023</span
            >
            Shenzhen Stock Exchange.
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import moment from "moment-timezone";
import axios from "@/axios.ts";

export default {
  name: "Home",
  data() {
    return {
      last: [],
      status: "*Close",
      date_show: "",
      data_price: 0,
      data_prev: 0,
      chart_data: [],
      ChartPlot: "",
      data_label: [],
      data_time: [],
      first: "",
      data: [],
      picupdata: [],
    };
  },
  created() {
    this.date_show = moment()
      .tz("Asia/Bangkok")
      .format("MMM/DD/YYYY");

    this.getLotto();
  },
  methods: {
    checkColor(num) {
      if (num) {
        if (Number(num) < 0) {
          return "red";
        } else {
          return "#0ea600";
        }
      }
    },
    numberFormat(x, decimal) {
      if (x) {
        if (decimal) {
          return Number(x).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        } else {
          return Number(x).toLocaleString(undefined);
        }
      }
    },
    showDate(datetime) {
      return datetime.split(" ")[0];
    },
    showDate2(datetime) {
      return moment(datetime)
        .add(1, "hours")
        .format("DD-MM-YYYY HH:mm");
    },
    showDate3(datetime) {
      if (datetime) {
        return moment(datetime)
          .add(-1, "days")
          .format("YYYY-MM-DD");
      }
    },
    async getLotto() {
      await axios.get("/list-v2/10").then((response) => {
        const data = response.data;
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          if (
            Number(
              moment(element.datetime)
                .add(-6, "hours")
                .tz("Asia/Bangkok")
                .format("YYYYMMDDHHmm")
            ) >
            Number(
              moment()
                .tz("Asia/Bangkok")
                .format("YYYYMMDDHHmm")
            )
          ) {
            continue;
          }
          this.data.push(element);
        }
        //     setInterval(() => {
        this.getLast();
        // }, 1000)
      });
    },
    async getLast() {
      const n = {
        indexDetailTmp: $("#indexDetail").html(),
        minuteWrap: $("#minutely"),
        dayWrap: $("#daily"),
        weekWrap: $("#weekly"),
        monthWrap: $("#monthly"),
        trendTabs: $(".index-trend .pic-tabs"),
        code: "399001",
        charts: {},
        chartDataState: {},
      };
      this.picupdata = [];
      await axios.get("/dowjone-chart").then((response) => {
        const data = response.data;

        let min = 0;
        let max = 0;
        for (let index = 0; index < data.length; index++) {
          const element = data[index];

          if (element) {
            element.createdate = moment(element.createdate)
              .tz("Asia/Bangkok")
              .add(-11, "hours")
              .format("YYYY-MM-DD HH:mm:ss");

            this.last = element;
            const d = moment(element.updatedate)
              .tz("Asia/Bangkok")
              .add(-11, "hours")
              .format("YYYY-MM-DD HH:mm:ss");
            const createdate1 = d.split(" ")[1];

            const time = createdate1.split(":");
            const time0 = (Number(time[0]) + 1).toString().padStart(2, "0");
            const amount100 = element.amount100mil.toString().split(".");
            const amount100mil = amount100[0] + "000";
            const amount100m2 = element.amount100m.toString().split(".");
            const amount100m = amount100m2[0] + amount100m2[1] + "027755.29";
            if (element.last_threeall) {
              this.picupdata.push([
                time0 + ":" + time[1],
                Number(element.last_threeall)
                  .toFixed(2)
                  .toString(),
                element.last_twoall.toString(),
                element.change.toString(),
                Number(amount100mil),
                // 3697911.0,
                Number(amount100m),
                // 5788027755.29
              ]);
            }
            min = element.min;
            max = element.max;
            // picupdata.push(
            // [
            //       "09:30",
            //       "11878.16",
            //       "-11.26",
            //       "-0.09",
            //       3697911.0,
            //       5788027755.29
            // ])
          }
        }
        const e = {
          datetime: moment(this.last.createdate)
            .tz("Asia/Bangkok")
            .format("YYYY-MM-DD HH:mm"),
          code: "0",
          data: {
            code: "399001",
            name: "深证成指",
            close: this.last.last_threeall,
            marketTime: moment(this.last.createdate)
              .tz("Asia/Bangkok")
              .format("YYYY-MM-DD HH:mm"),
            picupdata: this.picupdata,
            releInfo: {
              cfzqlb: "",
              zsdm: "399001",
              zslx: "",
              cfzqsclb: "",
            },
          },
          message: "成功",
        };
        var t = { data: e };
        if ($.isEmptyObject(n.charts.minuteChart)) {
          var a = new s.ui.controls.mLineChart(n.minuteWrap, t);
          n.charts.minuteChart = a;
        } else {
          n.charts.minuteChart.updateOption(t);
        }
      });
    },
  },
};
</script>

<style scoped>
body {
  font-family: "Lato", sans-serif;
}

.tbl {
  border-collapse: collapse;
  width: 94%;
  margin-left: 3%;
  margin-ringht: 3%;
}

.tbl th,
.tbl td {
  padding: 5px;
  border: solid 1px #777;
}

.tbl th {
  background-color: #5c0919;
}
</style>
